%reset_button {
	padding: 0;
	background-color: transparent;
	border: 0;
}

%base_button {
	position: relative;
	display: inline-block;
	font-size: 10px;
	text-transform: uppercase;
	color: var(--color-text-secondary);
	background-color: var(--color-accent-primary);

	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 16px;
	text-align: center;

	font-weight: bold;
	padding: 5px 11px;
	border-radius: 3px;
	
	text-align: center;

	cursor: pointer;
	
	transition: background-color 0.6s ease, color 0.6s ease, opacity 0.6s ease;

	span {
		color: inherit;
	}

	&.disabled {
		opacity: 0.25;
		pointer-events: none;
	}

	&:hover {
		color: white;
		text-decoration: none;
	}
}

%arrow_button {
	padding-right: 33px;

	.arrow-icon {
		position: absolute;
		top: 50%;
		right: 14px;
		transform: translate(0, -50%);
	}

	&.big {
		padding-right: 55px;

		.arrow-icon {
			right: 25px;
			width: 15px;
			height: 15px;
		}
	}
}

[class^=button] {
	@extend %base_button;

	&[class*=-arrow] {
		@extend %arrow_button;
	}
}