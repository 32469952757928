.feed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.qr-container {
		position: absolute;
		bottom: 0;
		left: 0px;

		background: rgba(#000, 0.3);
		backdrop-filter: blur(10px);
		padding: 20px;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: left;
		text-transform: uppercase;

		line-height: 1;
		font-weight: 700;
		font-size: 14px;

		.qr-code {
			position: relative;
			width: 100px;
			height: 100px;
			margin-bottom: 10px;

			path {
				fill: white;
			}
		}
	}

	&.enter,
	&.appear {
		.qr-container {
			opacity: 0;
			transform: translate(-100%, 0);
		}

		&-active {
			.qr-container {
				opacity: 1;
				transform: translate(0, 0);
				transition: all 0.9s $easeInOutCubic 0.6s;
			}
		}
	}
}