.page.home {
	position: relative;
	height: calc(var(--vh) * 100);

	background: #000;
	
	display: flex;
	flex-direction: column;

	align-items: center;
	justify-content: center;

	z-index: 1;

	.logo {
		position: relative;
		width: 20vw;
		margin-bottom: 40px;
	}

	.button {
		position: relative;
		padding: 30px;
		font-size: 18px;
	}

	video {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	&.enter,
	&.appear {
		opacity: 0;

		.logo,
		.button {
			opacity: 0;
			transform: translate(0, 40px);
		}
		
		&-active {
			opacity: 1;
			transition: opacity 0.6s $easeInOutCirc;

			.logo,
			.button {
				opacity: 1;
				transform: translate(0, 0);
			}

			.logo {
				transition: opacity 0.9s $easeOutSine 1s, transform 0.9s $easeOutSine 1s;
			}

			.button {
				transition: opacity 0.9s $easeOutSine 1.2s, transform 0.9s $easeOutSine 1.2s;
			}
		}
	}

	&.exit {
		opacity: 1;

		&-active {
			opacity: 0;
			transition: opacity 0.6s $easeInOutCirc;
		}
	}
}
